<template>
  <div>
    <b-modal id="successModal" title="Success" ok-only>The contract was sent to the client</b-modal>
    <b-modal id="errorModal" title="Error" ok-only>There was an error. Please let dev know.</b-modal>
  <b-container class="mt-5">
    <b-row>
      <b-col>
        <h2>Firms</h2>
      </b-col>
      <b-col cols="*" class="mr-3">
       
      </b-col>
    </b-row>
    <hr class="mt-1" />

    <div>
      <b-table
        id="firms-table"
        ref="table"
        :items="provider"
        :fields="fields"
        empty-text="No current firms found"
        show-empty
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

       <template #cell(actions)="row">
        <b-button variant="primary" @click="resendEmail(row)">Resend Email</b-button>
      </template>
        
      </b-table>

      <b-row align-h="end" class="mt-3">
        <b-col cols="*" class="mr-3"> {{ rows }} firms </b-col>
      </b-row>
    </div>
  </b-container>
  </div>
</template>

<script>

const API_URL = process.env.VUE_APP_BACKEND_URL;

export default {
  data() {
    return {
      rows: 0,
      fields: [
        { key: "firm_name", label: "Name" },
        { key: "status", label: "Status" },
        { key: "email", label: "Email" },
        { key: "phone", label: "Phone" },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  methods: {
    async provider() {
      try {
        let user = JSON.parse(localStorage.getItem('user'));
        var userHeader = {}
        if (user && user.accessToken) {
          //return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
          userHeader = { 'x-access-token': user.accessToken };       // for Node.js Express back-end
        }

        var response = await this.$http.post(
        API_URL + "api/sale/list",
          {
            email: user.email.trim(),
          },
          {
            headers: userHeader,
          }
        );
        console.log("data")
        console.log(response.data);




        this.rows = response.data.length;

        return response.data;
      } catch (error) {
        this.handleError(error);
        return [];
      }
    },
    async resendEmail(row) {
     
      console.log('resend email');
      console.log(row.item.email);
      try {
      let user = JSON.parse(localStorage.getItem('user'));
      var userHeader = {}
      if (user && user.accessToken) {
        //return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
        userHeader = { 'x-access-token': user.accessToken };       // for Node.js Express back-end
      }

      var response = await this.$http.post(
      API_URL + "api/sale/resend",
        {
          email: row.item.email,
        },
        {
          headers: userHeader,
        }
      );

      console.log("resent")
      this.$bvModal.show("successModal");

      } catch (error) {
         this.$bvModal.show("errorModal");
      
      }
    }
  },
};
</script>

<style>
</style>